import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MI, HD, SD } from 'UI/apps/RacingEventsApp/RaceStreaming';
import { Translations } from '../../../core__racing-events-app';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import StreamPlayer from './core__stream-player';

const RaceStreaming = ({ eventDetails }) => {
  const { sessionInfo } = useSelector(state => state.horseRacingData);
  const {
    videoStreamObj,
    streamProviderObject,
    isStreamDisplayed,
    videoQulifyMessage,
  } = useSelector(state => state.horseRacesList);

  const {
    provider,
    userQualified,
    flumenResource,
    sessionAvaiable,
    videoStreamingAvailable,
  } = streamProviderObject;

  const isStreamViewPortMounted =
    (sessionAvaiable || sessionInfo?.accessToken) &&
    userQualified &&
    videoStreamingAvailable &&
    Array.isArray(flumenResource);

  return (
    <MI isStreamViewPortMounted={isStreamViewPortMounted}>
      <React.Fragment>
        {!isStreamDisplayed && (
          <HD>{Translations.get('racing.live.tv.available')}</HD>
        )}

        {!sessionAvaiable && !sessionInfo?.accessToken ? (
          eventDetails['catRef'] === GLOBAL_CONSTANTS.HORSES ? (
            <SD>{Translations.get('racing.streaming.messagelogin')}</SD>
          ) : (
            <SD>{Translations.get('racing.streaming.messagelogin2')}</SD>
          )
        ) : !userQualified ? (
          <React.Fragment>
            <div>
              {(videoQulifyMessage &&
                Translations.get('racing.streaming.videoqualifymessage')) ||
                Translations.get('racing.streaming.videoqualifymessage.qn.1')}
              {videoStreamObj?.stakeamount}{' '}
            </div>
            <div>
              {Translations.get('racing.streaming.videoqualifymessage3')}
              {videoStreamObj?.stakeamount}.
              {Translations.get('racing.streaming.videoqualifymessage4')}
            </div>
            <div>
              {Translations.get('racing.streaming.videoqualifymessage5')}
            </div>
          </React.Fragment>
        ) : !videoStreamingAvailable ? (
          <div>{Translations.get('racing.streaming.available.message')}</div>
        ) : !Array.isArray(flumenResource) ? (
          <React.Fragment>
            <div>{Translations.get('racing.streaming.videoqualified1')}</div>
            <div>{Translations.get('racing.streaming.videoqualified2')}</div>
          </React.Fragment>
        ) : (
          <></>
        )}

        {isStreamViewPortMounted && <StreamPlayer provider={provider} />}
      </React.Fragment>
    </MI>
  );
};

RaceStreaming.propTypes = {
  videoStreamObj: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  sessionInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  streamProviderObject: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  isStreamDisplayed: PropTypes.bool,
  eventDetails: PropTypes.object,
  videoQulifyMessage: PropTypes.string,
};

RaceStreaming.defaultProps = {
  videoStreamObj: {},
  sessionInfo: {},
  streamProviderObject: {},
  isStreamDisplayed: false,
  videoQulifyMessage: '',
};

export const RaceStream = RaceStreaming;
