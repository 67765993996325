import {
  RC,
  RCB,
  RCT,
  RCN,
  NR,
  NRT,
  NRD,
  NRP,
  NRI,
  NRH,
  NRB,
  NRBM,
  NRS,
  NRDS,
  NRN,
  NRNE,
  NRJN,
  NRO,
  NRF,
  SC,
  ROB,
  RCV,
  NPT,
} from 'CORE__UI/apps/RacingEventsApp/core__raceCarousel';
import styled from 'styled-components';
import { GREYS, BK_COLORS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const RC_EX = styled(RC)`
  border: none;
  box-shadow: none;
  background-color: ${GREYS.white};
  transition: box-shadow 150ms ease;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-left: 8px;
    margin-right: 0;
  }

  &:hover {
    box-shadow: none;
  }
`;

const NRT_EX = styled(NRT)`
  color: ${FONT.black.standard};
  margin-bottom: 4px;
  padding-left: 16px;
`;

const RCT_EX = styled(RCT)`
  color: ${GREYS.black};
`;

const RCN_EX = styled(RCN)`
  color: ${GREYS.black};
`;

const NRH_EX = styled(NRH)`
  color: ${GREYS.white};
  background-color: ${BK_COLORS.blue};
  border-bottom: 1px solid ${BK_COLORS.aliceBlue10};
  @media (max-width: ${BREAKPOINT_S}) {
    border-radius: 0;
  }
`;

const NRF_EX = styled(NRF)`
  border-top: none;
  font-size: 12px;
  letter-spacing: 0.25px;
`;

const NR_EX = styled(NR)`
  margin-top: 0;
`;

const NRI_EX = styled(NRI)`
  box-shadow: none;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    border-radius: 0;
    height: auto;
  }
`;

const NRB_EX = styled(NRB)`
  @media (max-width: ${BREAKPOINT_S}) {
    height: auto;
  }
`;

const NRO_EX = styled(NRO)`
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 4px 8px 0;
  }
`;

const ROB_EX = styled(ROB)`
  &:hover {
    background-color: ${GREYS.doveL};
  }
`;

const NPT_EX = styled(NPT)`
  color: ${GREYS.white};
`;

const NRBM_EX = styled(NRBM)`
  border-bottom: 1px solid ${BK_COLORS.aliceBlue10};
`;

const NRJN_EX = styled(NRJN)`
  color: ${FONT.black.mid};
`;

export {
  RC_EX as RC,
  RCB,
  RCT_EX as RCT,
  RCN_EX as RCN,
  NR_EX as NR,
  NRT_EX as NRT,
  NRD,
  NRP,
  NRI_EX as NRI,
  NRH_EX as NRH,
  NRB_EX as NRB,
  NPT_EX as NPT,
  NRBM_EX as NRBM,
  NRS,
  NRDS,
  NRN,
  NRNE,
  NRJN_EX as NRJN,
  NRO_EX as NRO,
  NRF_EX as NRF,
  SC,
  ROB_EX as ROB,
  RCV,
};
