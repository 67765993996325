import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BetslipCurrency } from '../../BetslipContent/elements';
import Spinner from 'UI/spinner/Spinner';
import { Constants } from '../../../../../core__betslip-constants';
import {
  SBSD,
  SBSU,
  SBSL,
  SBSLH6,
  SBSLH6B,
  SBSNPB,
  SBSPB,
  SpinnerObj,
  BSB as BSB_LOCAL,
} from 'UI/apps/BetslipUKApp/BetslipContainer/BottomSection';
import { parseDecimalPlacesPotReturn } from 'Services/global/core__odds-format';
import { Translations } from '../../../../../core__betslip-UK-app';
import project from '../../../../../../../../project';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import I from 'UI/globals/Icons';
import { GLOBAL_CONSTANTS, getIcon } from 'Services/core__services';
import { BSB as BSB_CORE } from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__bottomSection';
import { getComponent } from 'Services/core__imports';
import {
  checkIsValidBet,
  filterSuspendedSelections,
} from '../../../../../core__betslip-utils';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import PubSub from 'Services/pubsub/core__pubsub';

const BSB = getComponent(BSB_LOCAL, BSB_CORE);

/**
 @param {func} handleDelete
 @param {bool} suspended
 @param {func} handlePlaceBet
 @param {bool} isLogin
 @param {object} UserAuthData
 @param {string} region
 @param {bool} isError
 @param {number} tax
 @param {func} handleWsAcceptDecline
 @param {func} postApiCall
 @param {string} taxbycountry
 @param {number} potentialReturns
 @param {number} totalStake
 @param {string} currencySymbol
 @param {object} freebetStake
 @param {number} netReturn
 @param {bool} countryCode
 @param {array} betTemplate

 */
export const BottomSection = ({
  handleDelete,
  potentialReturns,
  totalStake,
  currencySymbol,
  suspended,
  handlePlaceBet,
  isLogin,
  freebetStake,
  isError,
  fee,
  netReturn,
  tax,
  checkChanges,
  handleWsAcceptDecline,
  postApiCall,
  userbalance,
  card,
  SB,
  hideGrossValsInJN,
  countryCode,
  hasBetCategoriesWithTax,
  openTaxSection,
  betTemplate,
  isMaxBetError,
  isOddsAcceptance,
  useChatWithBroker,
}) => {
  let showDeposit = false;
  let showQuickDeposit = false;
  let active = false;
  let disable = true;
  let type = null;
  if (!checkIsValidBet(betTemplate)) {
    active = false;
    disable = true;
  } else if (isError === false && totalStake > 0) {
    active = true;
    disable = false;
  } else if (
    isError === false &&
    freebetStake &&
    freebetStake['freeStake'] > 0
  ) {
    active = true;
    disable = false;
  }

  // handle when amount is less then user stake
  if (!SB && userbalance && isLogin && Object.keys(userbalance).length > 0) {
    if (!card && userbalance.balance < totalStake) {
      showDeposit = true;
    } else if (card && userbalance.balance < totalStake) {
      showQuickDeposit = true;
    }
  }

  const [showLogin, setShowLogin] = useState(null);
  if (checkChanges) {
    type = GLOBAL_CONSTANTS.ACCEPT;
  }

  useEffect(() => {
    // Deplay to get the session of the user
    setTimeout(() => {
      const userAuthData = FSBCustomerSession.getSession();
      if (userAuthData?.accessToken) {
        setShowLogin(true);
      } else {
        setShowLogin(false);
      }
    }, 1500);
  }, []);

  const openChatBox = () => {
    PubSub.emit(
      PubsubEvents.ChatWithBroker,
      Translations.get('text.chat.with.broker.msg')
    );
  };
  const handleOddsChangesAndPlacebet = type => {
    if (type !== null) handleWsAcceptDecline(type);
    handlePlaceBet();
  };

  useEffect(() => {
    if (suspended) {
      const timeout = setTimeout(() => {
        const selections = betTemplate.filter(b => b.id !== undefined);
        const activeSelections = filterSuspendedSelections(selections);

        const equal =
          JSON.stringify(selections.map(s => s.id)) ===
          JSON.stringify(activeSelections.map(s => s.id));
        !equal && handleDelete({ type: 'deletesuspended' });
      }, 1000);

      return () => {
        timeout && clearTimeout(timeout);
      };
    }
  }, [suspended, betTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  const [isSafari, setisSafari] = useState('false');
  useEffect(() => {
    // handle safari issue
    try {
      setisSafari(
        /Safari/.test(window?.navigator.userAgent) &&
          /Apple Computer/.test(window?.navigator.vendor)
      );
    } catch (error) {
      //no handling required
    }
  }, []);

  if (isLogin && isError && isMaxBetError && useChatWithBroker) {
    return (
      <SBSD isSafari={isSafari}>
        <SBSPB active={true} disabled={false} onClick={openChatBox}>
          {Translations.get('text.chat.with.broker')}
        </SBSPB>
      </SBSD>
    );
  }

  if (isLogin && isError && isMaxBetError && !useChatWithBroker) {
    return (
      <SBSD isSafari={isSafari}>
        <SBSPB
          aria-label={Constants.PLACEBETLABEL}
          active={true}
          disabled={true}
        >
          {Translations.get('text.place.bet')}
        </SBSPB>
      </SBSD>
    );
  }

  return (
    <SBSD isSafari={isSafari}>
      {freebetStake['hasFreebet'] && (
        <SBSU>
          <SBSL>
            <SBSLH6>{Translations.get(Constants.TFS)}</SBSLH6>
          </SBSL>
          <SBSL>
            <SBSLH6>
              <BetslipCurrency symbol={currencySymbol} />
              {parseDecimalPlacesPotReturn(freebetStake['freeStake'])}
            </SBSLH6>
          </SBSL>
        </SBSU>
      )}

      <SBSU>
        <SBSL>
          <SBSLH6>{Translations.get(Constants.TS)}</SBSLH6>
        </SBSL>
        <SBSL>
          <SBSLH6>
            <BetslipCurrency symbol={currencySymbol} />
            {parseDecimalPlacesPotReturn(totalStake)}
          </SBSLH6>
        </SBSL>
      </SBSU>

      {!isLogin && (
        <SBSU>
          <SBSL>
            <SBSLH6B>
              {Translations.get('text.potential.returns')}{' '}
              {hasBetCategoriesWithTax && (
                <>
                  ({tax}% Tax){' '}
                  {
                    <BSB onClick={openTaxSection}>
                      <I iconName={getIcon('INFO')} />
                    </BSB>
                  }
                </>
              )}
            </SBSLH6B>
          </SBSL>
          <SBSL>
            {suspended ? (
              <SBSLH6B>{Translations.get('text.na')}</SBSLH6B>
            ) : (
              <SBSLH6B>
                {potentialReturns === Constants.N_A || checkChanges ? (
                  Translations.get('text.na')
                ) : (
                  <React.Fragment>
                    <BetslipCurrency symbol={currencySymbol} />
                    {!hasBetCategoriesWithTax
                      ? parseDecimalPlacesPotReturn(potentialReturns)
                      : parseDecimalPlacesPotReturn(netReturn)}
                  </React.Fragment>
                )}
              </SBSLH6B>
            )}
          </SBSL>
        </SBSU>
      )}
      {isLogin && (
        <SBSU>
          <SBSL>
            {hideGrossValsInJN ? (
              <SBSLH6B>
                {Translations.get('text.potential.returns')}{' '}
                {hasBetCategoriesWithTax && (
                  <>
                    ({tax}% Tax){' '}
                    {
                      <BSB onClick={openTaxSection}>
                        <I iconName={getIcon('INFO')} />
                      </BSB>
                    }
                  </>
                )}
              </SBSLH6B>
            ) : (
              <SBSLH6>
                {(tax && Translations.get(Constants.TRG)) ||
                  Translations.get(Constants.TOTALRETURN)}
              </SBSLH6>
            )}
          </SBSL>
          <SBSL>
            <SBSLH6B>
              {potentialReturns === Constants.N_A || checkChanges ? (
                Translations.get('text.na')
              ) : (
                <React.Fragment>
                  <BetslipCurrency symbol={currencySymbol} />
                  {!hasBetCategoriesWithTax
                    ? parseDecimalPlacesPotReturn(potentialReturns)
                    : parseDecimalPlacesPotReturn(netReturn)}
                </React.Fragment>
              )}
            </SBSLH6B>
          </SBSL>
        </SBSU>
      )}

      {countryCode !== 'CAD' && tax && isLogin && !hideGrossValsInJN && (
        <SBSU>
          <SBSL>
            <SBSLH6>{tax + Translations.get(Constants.FEE)}</SBSLH6>
          </SBSL>
          <SBSL>
            <SBSLH6>
              {'-'}
              <BetslipCurrency symbol={currencySymbol} />
              {parseDecimalPlacesPotReturn(fee)}
            </SBSLH6>
          </SBSL>
        </SBSU>
      )}

      {tax && isLogin && !hideGrossValsInJN && (
        <SBSU>
          <SBSL>
            <SBSLH6>{Translations.get(Constants.TRN)}</SBSLH6>
          </SBSL>
          <SBSL>
            <SBSLH6>
              <BetslipCurrency symbol={currencySymbol} />
              {parseDecimalPlacesPotReturn(netReturn)}
            </SBSLH6>
          </SBSL>
        </SBSU>
      )}

      {suspended === true ? (
        <SBSNPB
          aria-label={Constants.Deletesuspended}
          onClick={() => handleDelete({ type: 'deletesuspended' })}
        >
          {Translations.get(Constants.REMOVESUSPENDED)}
        </SBSNPB>
      ) : postApiCall === true ? (
        <SBSPB
          onClick={() => {
            handlePlaceBet();
          }}
          active={true}
          disabled={true}
          aria-label={
            isLogin ? Constants.PLACEBETLABEL : Constants.LOGINANDPLACEBETLABEL
          }
        >
          <Spinner display={true} width={24} style={SpinnerObj} />
          {isLogin
            ? Translations.get(Constants.PLACEBET)
            : Translations.get('text.login') +
              ' & ' +
              Translations.get('text.place.bet')}
        </SBSPB>
      ) : showDeposit ? (
        <SBSPB
          onClick={() => {
            handlePlaceBet('deposit');
          }}
          active={active}
          disabled={disable}
          aria-label={Constants.DEPOSITLABEL}
        >
          {Translations.get('text.deposit')}
        </SBSPB>
      ) : showQuickDeposit ? (
        <SBSPB
          onClick={() => {
            handlePlaceBet('low-balance');
          }}
          active={active}
          disabled={disable}
          aria-label={Constants.DEPOSITANDPLACEBETLABEL}
        >
          {Translations.get('text.deposit')}
          {' & '}
          {Translations.get('text.place.bet')}
        </SBSPB>
      ) : !isLogin ? (
        <SBSPB
          onClick={() => {
            handlePlaceBet();
          }}
          active={true}
          aria-label={Constants.LOGINANDPLACEBETLABEL}
        >
          {Translations.get('text.login') +
            ' & ' +
            Translations.get('text.place.bet')}
        </SBSPB>
      ) : !(isOddsAcceptance && active) && checkChanges ? (
        <SBSNPB
          aria-label={Constants.ACCEPTODDSLABEL}
          onClick={() => handleWsAcceptDecline('accept')}
        >
          {Translations.get(Constants.ACCEPTSODDS)}
        </SBSNPB>
      ) : (
        <SBSPB
          onClick={() => {
            handleOddsChangesAndPlacebet(type);
          }}
          active={active}
          disabled={disable}
          aria-label={
            project.JpTimeFormat && showLogin === null
              ? ' '
              : isLogin && checkChanges
              ? `${Constants.ACCEPTODDSLABEL} & ${Constants.PLACEBETLABEL}`
              : checkChanges
              ? Constants.ACCEPTODDSLABEL
              : Constants.PLACEBETLABEL
          }
        >
          {project.JpTimeFormat && showLogin === null
            ? ' '
            : isLogin && checkChanges
            ? `${Translations.get(Constants.ACCEPTSODDS)} & ${Translations.get(
                Constants.PLACEBET
              )}`
            : checkChanges
            ? Translations.get(Constants.ACCEPTSODDS)
            : Translations.get(Constants.PLACEBET)}
        </SBSPB>
      )}
    </SBSD>
  );
};

BottomSection.propTypes = {
  handleDelete: PropTypes.func,
  handlePlaceBet: PropTypes.func,
  potentialReturns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalStake: PropTypes.number,
  suspended: PropTypes.bool,
  currencySymbol: PropTypes.string,
  isLogin: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  freebetStake: PropTypes.shape({
    freeStake: PropTypes.number,
    hasFreebet: PropTypes.bool,
  }),
  freeStake: PropTypes.number,
  hasFreebet: PropTypes.bool,
  isError: PropTypes.bool,
  netReturn: PropTypes.number,
  fee: PropTypes.number,
  tax: PropTypes.number,
  checkChanges: PropTypes.bool,
  handleWsAcceptDecline: PropTypes.func,
  postApiCall: PropTypes.bool,
  card: PropTypes.bool,
  SB: PropTypes.bool,
  userbalance: PropTypes.object,
  hideGrossValsInJN: PropTypes.bool,
  countryCode: PropTypes.string,
  hasBetCategoriesWithTax: PropTypes.bool,
  openTaxSection: PropTypes.func,
  bonusStake: PropTypes.object,
  betTemplate: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isMaxBetError: PropTypes.bool,
  isOddsAcceptance: PropTypes.bool,
  isSafari: PropTypes.bool,
  useChatWithBroker: PropTypes.bool,
};

BottomSection.defaultProps = {
  hasBetCategoriesWithTax: false,
};
