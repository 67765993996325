import {
  BL,
  BCS,
  BSSEL,
  OI,
  BLS,
  BH,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__price';
import styled from 'styled-components';
import { BK_COLORS } from 'UI/globals/colours';

const BL_QN = styled(BL)`
  background-color: ${props => props.bbFlag && BK_COLORS.blue};
  border-radius: ${props => props.bbFlag && '4px'};
  width: ${props => props.bbFlag && 'auto'};
  height: ${props => props.bbFlag && '32px'};
  padding: ${props => props.bbFlag && '4px'};
`;

const BCS_QN = styled(BCS)`
  display: ${props => props.bbFlag && 'flex'};
  justify-content: ${props => props.bbFlag && 'center'};
  align-items: ${props => props.bbFlag && 'center'};
  margin: ${props => props.bbFlag && '4px'};
`;

const BH_QN = styled(BH)`
  color: ${props => props.suspended && BK_COLORS.red};
`;

export { BL_QN as BL, BCS_QN as BCS, BSSEL, OI, BLS, BH_QN as BH };
