import {
  BSI,
  BSM,
  BAMY,
  BA,
  BS,
  BHLF,
  BHL,
  BHU,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__head';
import styled from 'styled-components';
import { BRAND, EX_COLORS, FONT, GREYS } from 'UI/globals/colours';

const BHU_QN = styled(BHU)`
  background-color: ${EX_COLORS.brandWhitey || GREYS.black};
  border-top: 1px solid ${GREYS.white};

  a span {
    color: ${GREYS.black};
  }
`;

const BAMY_QN = styled(BAMY)`
  display: none;
`;
const BS_QN = styled(BS)`
  width: 16px;
  height: 16px;
  border-radius: 26px;
  background-color: ${BRAND.secondary};
  color: ${FONT.dark};
  top: 0;
  font-size: 10px;
  font-weight: 700;
  right: -20px;
`;

const BA_QN = styled(BA)`
  color: ${GREYS.white};
  border-bottom: 4px solid ${BRAND.primary};
`;
export {
  BSI,
  BSM,
  BAMY_QN as BAMY,
  BA_QN as BA,
  BS_QN as BS,
  BHLF,
  BHL,
  BHU_QN as BHU,
};
