import {
  MSSD,
  M2,
  MSB,
  M,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__error';
import styled from 'styled-components';
import { GREYS } from '../../../globals/colours';

const MSSD_QN = styled(MSSD)`
  background-color: ${GREYS.dove};
`;
const M_QN = styled(M)`
  background-color: ${GREYS.dove};
`;
const M2_QN = styled(M2)`
  background-color: ${GREYS.dove};
`;
const MSB_QN = styled(MSB)`
  background-color: ${GREYS.dove};
`;
export { MSSD_QN as MSSD, M2_QN as M2, MSB_QN as MSB, M_QN as M };
