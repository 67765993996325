import { BetSlipBonus } from './core__bonus';
import { BetslipContentBooster } from './core__booster';
import {
  CastOrderComponent,
  ordinalSuffixOf,
  dataSetOnupdate,
} from './core__castorder';
import { CrossButton } from './core__crossbutton';
import { BetslipCurrency } from './core__currency';
import { Description } from './core__description';
import { BetslipEW } from './core__ew';
import { BetslipFreeBet } from './core__freebet';
import { UserFreeBetStake } from './core__freebetstake';
import { OddName } from './core__name';
import { PotReturn } from './core__potreturn';
import { Price } from './core__price';
import { SocketMessage } from './core__socketmessage';
import { BetslipUserStake } from './core__stake';
import { Text } from './core__text';
import React from 'react';

const BetSlipBonus_EX = props => <BetSlipBonus universal={false} {...props} />;
const BetslipEW_EX = props => <BetslipEW universal={false} {...props} />;
const BetslipFreeBet_EX = props => (
  <BetslipFreeBet isJennings={true} {...props} />
);
export {
  BetSlipBonus_EX as BetSlipBonus,
  BetslipContentBooster,
  CastOrderComponent,
  CrossButton,
  ordinalSuffixOf,
  dataSetOnupdate,
  BetslipCurrency,
  Description,
  BetslipEW_EX as BetslipEW,
  BetslipFreeBet_EX as BetslipFreeBet,
  UserFreeBetStake,
  OddName,
  PotReturn,
  Price,
  SocketMessage,
  BetslipUserStake,
  Text,
};
