import {
  RL,
  FT,
  FRMD,
  FRTE,
  FB,
  MKN,
  FRSO,
  FROT,
  FROM,
  FRO,
  B,
  FRF,
} from '../../../core__ui-library/apps/RacingEventsApp/core__futureRacesOutcomes';
import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';

const MKN_EX = styled(MKN)`
  background-color: ${GREYS.black};
  color: ${GREYS.white};
  border-bottom: none;
`;

export { RL, FT, FRMD, FRTE, FB, MKN_EX as MKN, FRSO, FROT, FROM, FRO, B, FRF };
