import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { getRequest, postRequest } from 'Services/http/core__axios';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import {
  generateBetBuilderObject,
  getMinimalBetModifierBetCount,
  notificationValues,
} from './core__betslip-utils';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import TRACKING_CONSTANTS from 'Services/constants/core__tracking';
import { getCookie } from 'Services/cookie/core__cookies';
import { Constants } from './core__betslip-constants';
export function getUrl() {
  const url = `.fsb`;
  const betTemplate = `/fsb-api-rest/bet/template`;
  const betPost = `/fsb-api-rest/bet`;
  const betBuilder = `/fsb-api-rest/bet/builder`;
  const priceAcceptance = `/fsb-api-rest/platformConfig/priceChange`;
  return {
    betBuilder: `${betBuilder}${url}`,
    betTemplate: `${betTemplate}${url}`,
    betPost: `${betPost}${url}`,
    priceAcceptance: `${priceAcceptance}${url}`,
  };
}

//REACT Redux
export const dataReducer = (state = [], action) => {
  let od = [];
  switch (action.type) {
    case 'CHECK_SELECTIONS':
      return {
        ...state,
        prevSelectionsUnFulfilled: { [`${action.checkSelectionId}`]: true },
      };
    case 'CHECK_SELECTIONS_FULFILLED': {
      const newState = { ...state };
      delete newState.prevSelectionsUnFulfilled[`${action.checkSelectionId}`];
      return newState;
    }
    case 'STORE_DATA': {
      let count = 0;
      const SINGLE_BET = 'SGL';
      const market = [];
      if (
        action?.data &&
        action.data.betTemplate &&
        Array.isArray(action.data.betTemplate)
      ) {
        action.data.betTemplate.forEach(x => {
          if (x.betTypeRef === SINGLE_BET && x.nextBetBonus) {
            market.push(x.eventId);
          }
        });
        const list = new Set(market);
        count = Array.from(list).length;
        const findBonus = action.data.betTemplate.filter(
          x => x.betTypeRef !== SINGLE_BET
        );
        let nextBetBonusIndex;
        if (
          findBonus &&
          findBonus.length > 0 &&
          findBonus.find(x => x.nextBetBonus)
        ) {
          action.data.betTemplate.map((x, index) => {
            x['hideBetBonus'] = true;
            if (x.nextBetBonus) {
              nextBetBonusIndex = index;
            }
          });
          action.data.betTemplate[nextBetBonusIndex].hideBetBonus = false;
        } else {
          action.data.betTemplate.map((x, index) => {
            x['hideBetBonus'] = false;
            if (x.betTypeRef === SINGLE_BET && x.nextBetBonus) {
              if (index !== count - 1) {
                x['hideBetBonus'] = true;
              } else {
                x['hideBetBonus'] = false;
              }
            }
          });
        }
        action.data['count'] = count;
      }

      return {
        ...state,
        useBet: {},
        placedBet: {},
        builderData:
          typeof state.builderData !== 'undefined' ? state.builderData : [],
        betTemplate: action.data.betTemplate,
        countAccaBet: action?.data?.count,
      };
    }
    case 'STORE_USER_BET_INFO':
      return { ...state, useBet: { ...state.useBet, ...action.data } };
    case 'POST_STORE_DATA':
      return { ...state, placedBet: action.data };
    case 'STORE_BUILDER_DATA':
      if (typeof state.builderData !== 'undefined') {
        od = state.builderData;
      }
      return {
        ...state,
        builderData: [...od, ...action.dataB],
      };
    case 'STORE_CLEAR_BETSLIP':
      return {
        ...state,
        useBet: {},
        placedBet: {},
        selectionId: [],
        betTemplate: action.data.betTemplate,
        builderData: action.data.dataB,
      };
    case 'PRICE_NOT_CURRENT_ERROR':
      return {
        ...state,
        priceChangedForBetId: action.data,
      };
    default:
      return state;
  }
};

export const reducer = combineReducers({ data: dataReducer });

/**
 *  STORE_DATA
 * it contain betTemplate response
 * @param data Array object
 */
export const storeData = (data, ordinalData = {}) => {
  // handle forcast/tricast data and genrate Id in system
  data = handleForcastDataAndGenerateId(data, ordinalData);
  // find market id and emit to pubsub
  sendMarketArrayToPubSub(data);
  return { type: 'STORE_DATA', data };
};

/**
 * POST_STORE_DATA
 * it contain response of Bet Post Api
 * @param data Array object
 */

export const poststoreData = data => {
  const newData = data;
  if (newData?.status?.returnCode === 'SUCCESS') {
    // push one pubsub event to header app
    PubSub.emit(PubsubEvents.UPDATE_WALLET, 'update wallet data of user');
    PubSub.emit(PubsubEvents.SESSION_UPDATE);
  }
  return { type: 'POST_STORE_DATA', data: newData };
};

/**
 * POST_
 * it contain response of Bet Post Api
 * @param data Array object
 */

export const postBulderData = data => {
  if (data?.bbSoccerEvent) {
    const newData = generateBetBuilderObject(data.bbSoccerEvent);
    const dataB = [];
    dataB.push(newData);
    return { type: 'STORE_BUILDER_DATA', dataB };
  }
};

/**
 * STORE_USER_BET_INFO
 * it contain all info of user bet
 * @param bet Array object
 */
export const userBetInfo = bet => {
  const newTemp = {};
  newTemp[bet['id']] = bet;
  return { type: 'STORE_USER_BET_INFO', data: newTemp };
};

/**
 * for API call GET
 * @param url string end point urL
 * @param data data which we pass in api
 * @param nodeRequest function
 */
export const fetchData = (
  url,
  nodeRequest,
  appConfig,
  ordinalData,
  checkSelectionId = null
) => dispatch => {
  // when all login stuff completed then we active this token
  const UserAuthData = FSBCustomerSession.getSession();
  if (UserAuthData && UserAuthData['accessToken']) {
    if (url.indexOf('?') > -1) {
      url = `${url}&access_token=${UserAuthData['accessToken']}`;
    } else {
      url = `${url}?access_token=${UserAuthData['accessToken']}`;
    }
  }
  dispatch({ type: 'CHECK_SELECTIONS', checkSelectionId });
  const requester = !nodeRequest ? getRequest : nodeRequest;
  return requester(url).then(res => {
    dispatch({ type: 'CHECK_SELECTIONS_FULFILLED', checkSelectionId });
    if (res.statusCode !== 500) {
      let betTemplate = [];
      const gtmProduct = appConfig.requestURL.split('/').filter(v => v)[0];
      if (res.betTemplate) {
        const minBetCount = getMinimalBetModifierBetCount(
          res.betTemplate || []
        );

        betTemplate = res.betTemplate.map(tpl => ({
          ...tpl,
          gtmProduct: tpl.gtmProduct || gtmProduct,
          disappear:
            !appConfig?.ismultiplehidden ||
            !tpl.multiple ||
            !appConfig?.showmultipleexpandable ||
            [Constants.TRICAST, Constants.FORCAST].includes(tpl.name)
              ? false
              : tpl.betModifierBetCount > minBetCount ||
                (tpl.betModifier?.length > 0 &&
                  tpl.betModifier[0]?.betCount > minBetCount),
        }));
      }
      return dispatch(
        storeData(
          {
            ...res,
            betTemplate,
            appConfig,
          },
          ordinalData
        )
      );
    }
  });
};
/**
 * for API call POST
 * @param url string end point urL
 * @param data data which we pass in api
 * @param nodeRequest function
 */
export const postData = (url, data, nodeRequest) => (dispatch, getState) => {
  const UserAuthData = FSBCustomerSession.getSession();
  if (UserAuthData && UserAuthData['accessToken']) {
    if (url.indexOf('?') > -1) {
      url = `${url}&access_token=${UserAuthData['accessToken']}`;
    } else {
      url = `${url}?access_token=${UserAuthData['accessToken']}`;
    }
  }

  const store = getState();
  const checkIsFirstBet = false; // #TBC
  let eventProducts = {};
  const betWithPrices = {};
  if (Array.isArray(store.data.betTemplate)) {
    eventProducts = store.data.betTemplate.reduce(
      (acc, tpl) => ({ ...acc, [tpl.eventId]: tpl.gtmProduct }),
      {}
    );

    store.data.betTemplate.forEach(b => {
      const priceId = b.price?.length && b.price[0].id;
      if (priceId) betWithPrices[priceId] = b.id;
    });

    PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
      event: TRACKING_CONSTANTS.BET_SUBMITTED,
      data: {
        customerId: getCookie('custId'),
        product: eventProducts[0],
      },
    });
  }

  const requester = !nodeRequest ? postRequest : nodeRequest;
  return requester(url, data)
    .then(res => {
      dispatch(poststoreData(res));

      if (res.inError) {
        let dataParsed = data.br.replace('\\', '').trim();
        dataParsed = JSON.parse(dataParsed);
        PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
          event: TRACKING_CONSTANTS.BET_FAILED,
          data: {
            message: res.response.status.value,
            customerId: getCookie('custId'),
            currencyCode: getCookie('currencyCode'),
            betStake: dataParsed.totalStake,
            product: eventProducts && eventProducts[0],
            betType: dataParsed.betTypeRef,
          },
        });

        if (
          res.response?.status?.messageKey ===
            Constants.PRICE_NOT_CURRENT_ERROR_CODE ||
          res.response?.status?.messageKey === Constants.PRICE_CHANGE_ERROR_CODE
        ) {
          const priceId = (
            res.response?.status?.devMessage ||
            res.response?.status?.value ||
            ''
          )
            ?.split('ref ')[1]
            ?.slice(0, -1);

          dispatch({
            type: 'PRICE_NOT_CURRENT_ERROR',
            data: betWithPrices[priceId],
          });
        }

        return;
      }

      // attach gtmProduct to response bets
      const bets = res.customer.bet.map(bet => ({
        ...bet,
        gtmProduct: eventProducts && eventProducts[bet.betPart[0].eventId],
      }));
      bets.forEach(bet => {
        PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
          event: TRACKING_CONSTANTS.BET_SUCCESS,
          data: {
            customerId: res.customer.id,
            isFirstBet: checkIsFirstBet,
            couponId: bet.walletId,
            currencyCode: res.customer.currencyCode,
            betStake: bet.totalStake,
            product: bet.gtmProduct,
            betType: bet.betTypeName,
          },
        });
      });
    })
    .catch(err => {
      let dataParsed = data.br.replace('\\', '').trim();
      dataParsed = JSON.parse(dataParsed);
      PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
        event: TRACKING_CONSTANTS.BET_FAILED,
        data: {
          message: err.message,
          customerId: getCookie('custId'),
          currencyCode: getCookie('currencyCode'),
          betStake: dataParsed.totalStake,
          product: eventProducts && eventProducts[0],
          betType: dataParsed.betTypeRef,
        },
      });
    });
};

/**
 * for API call POST
 * @param url string end point urL
 * @param data data which we pass in api
 * @param nodeRequest function
 */
export const postDataAsync = (url, data, nodeRequest) => dispatch => {
  const UserAuthData = FSBCustomerSession.getSession();
  if (UserAuthData && UserAuthData['accessToken']) {
    if (url.indexOf('?') > -1) {
      url = `${url}`;
    } else {
      url = `${url}`;
    }
  }
  const requester = !nodeRequest ? postRequest : nodeRequest;

  return new Promise(resolve => {
    const resp = requester(url, data);

    resp.then(res => {
      if (url.indexOf('builder') > -1) {
        dispatch(postBulderData(res));
      } else {
        dispatch(poststoreData(res));
      }
      resolve(1);
    });
  });
};

/** 
handleForcastDataAndGenerateId
this function handle forcast and tricast logics also it generate Id in bettemplate api response 
@param data object data of store return same with manipulation of betTemplate data 
*/
export const handleForcastDataAndGenerateId = (data, ordinalData) => {
  const newBetTemplate = [];
  const betTemplate = data.betTemplate && [...data.betTemplate];
  if (betTemplate && betTemplate.length > 0) {
    for (const itm of betTemplate) {
      if (
        itm.name === GLOBAL_CONSTANTS.TRICAST ||
        itm.name === GLOBAL_CONSTANTS.FORECAST
      ) {
        if (itm.betModifier.length >= 1) {
          for (const bmf of itm.betModifier) {
            const id = itm.name + '_' + bmf.type;
            newBetTemplate.push({
              ...itm,
              betModifier: [bmf],
              id: id,
              active: true,
            });
          }
        } else {
          newBetTemplate.push({
            ...itm,
            id: itm['selectionId'] ? itm['selectionId'] : itm['name'],
            active: true,
            ordinal:
              ordinalData.selectionId === itm['selectionId'] &&
              ordinalData.ordinalData,
            eventProviderRef:
              ordinalData.selectionId === itm['selectionId'] &&
              ordinalData.eventProviderRef,
          });
        }
      } else {
        newBetTemplate.push({
          ...itm,
          disappear: itm.disappear || false,
          id: itm['selectionId'] ? itm['selectionId'] : itm['name'],
          active: true,
          ordinal:
            ordinalData.selectionId === itm['selectionId'] &&
            ordinalData.ordinalData,
          eventProviderRef:
            ordinalData.selectionId === itm['selectionId'] &&
            ordinalData.eventProviderRef,
        });
      }
    }
  }
  return { ...data, betTemplate: newBetTemplate };
};

/**
 * send market id from ws socket
 * @param {array} bet template
 */

const sendMarketArrayToPubSub = data => {
  const betTemplate = [...data.betTemplate];
  let marketArr = new Array();
  marketArr = betTemplate
    .map(item => {
      if (item && typeof item['marketId'] !== 'undefined') {
        return item['marketId'];
      }
    })
    .filter(item => item);
  if (marketArr.length > 0) {
    const resultArr = marketArr.filter((data, index) => {
      return marketArr.indexOf(data) === index;
    });
    PubSub.emit(PubsubEvents.postMarketIdToWS, resultArr);
  }
};

/**
Track User Odd preferences
@param REQUESTED - will only accept if the requested price didn't change 
@param CURRENT - allow any price, ie will use whichever price is now current
@param HIGHER - accept bet if current price is higher than the request one
@param LOWER - accept bet if current passed is lower 
// for lower we don`t have condition 
*/

export const postUserOddPreferences = (url, notificationParam) => dispatch => {
  const UserAuthData = FSBCustomerSession.getSession();
  if (UserAuthData && UserAuthData['accessToken']) {
    const value = notificationValues(notificationParam);
    const data = {
      access_token: UserAuthData['accessToken'],
      value: value,
    };

    const requester = postRequest;
    return requester(url, data).then(res => {
      if (res) {
        return dispatch({ type: 'STORE_DATA_PREFERENCES', res });
      }
    });
  }
};

const composeEnhancers = getComposeEnhancers({ name: 'betslip-uk-store' });

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
