import {
  M,
  MSSDNF,
  M3,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__notification';
import styled from 'styled-components';
import { GREYS, BK_COLORS, FONT } from 'UI/globals/colours';

const MSSDNF_QN = styled(MSSDNF)`
  background-color: ${GREYS.dove};
`;
const M_QN = styled(M)`
  background-color: ${GREYS.dove};
`;
const M3_QN = styled(M3)`
  background-color: ${BK_COLORS?.blue || GREYS.dove};
  color: ${FONT.light};
`;
export { M_QN as M, MSSDNF_QN as MSSDNF, M3_QN as M3 };
