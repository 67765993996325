import {
  BTU,
  BTL,
  BTSS,
  BTCB,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__tab';
import styled from 'styled-components';
import { GREYS } from '../../../globals/colours';

const BTL_QN = styled(BTL)`
  color: ${GREYS.black};
`;

export { BTU, BTL_QN as BTL, BTSS, BTCB };
