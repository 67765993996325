import {
  RL,
  FRT,
  FRMD,
  FRD,
  FRSM,
  FRS,
  FRA,
} from '../../../core__ui-library/apps/RacingEventsApp/core__futureRaces';
import styled from 'styled-components';
import { GREYS, FONT, BK_COLORS } from 'UI/globals/colours';

const FRT_EX = styled(FRT)`
  background-color: ${BK_COLORS.blue};
  color: ${GREYS.white};
  border-bottom: none;
`;

const FRMD_EX = styled(FRMD)`
  background-color: ${BK_COLORS.EVENTS.bodySection};
  border-bottom: 1px solid ${BK_COLORS.aliceBlue10};
  border-top: 1px solid ${BK_COLORS.aliceBlue10};
  margin-bottom: 0;
`;

const FRD_EX = styled(FRD)`
  color: ${FONT.black.label};
  font-weight: 800;
`;

const FRSM_EX = styled(FRSM)`
  border-bottom: ${BK_COLORS.aliceBlue10};
`;

const FRA_EX = styled(FRA)`
  &:after {
    color: ${FONT.black.label};
  }
`;

const RL_EX = styled(RL)`
  border: 1px solid ${BK_COLORS.aliceBlue10};
  box-shadow: none;
`;

export {
  RL_EX as RL,
  FRT_EX as FRT,
  FRMD_EX as FRMD,
  FRD_EX as FRD,
  FRSM_EX as FRSM,
  FRS,
  FRA_EX as FRA,
};
