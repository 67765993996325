import React from 'react';
import PropTypes from 'prop-types';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { RaceSelections } from '../RaceOutcomesSelection';
import { RaceMarkets } from '../RaceOutcomesMarkets';
import { RaceFavourite } from '../RaceOutcomesFavourite';
import moment from 'moment';
import drawnPosition from '../../helper/position-color.json';
import { RaceStream } from '../RaceStreaming';
import { RaceNR } from '../RaceNonRunners';
import {
  MD,
  RP,
  RIDM,
  RID,
  RIR,
  IU,
  IL,
  MI,
  TI,
  OI,
  TIH,
  TIJ,
  TII,
  TBB,
  OIO,
  ED,
  EDB,
  RS,
  RA,
  OIF,
  HRI,
  RST,
  HM,
  WB,
  OT,
  SO,
  ST,
  STS as STS_LOCAL,
  RaceInfo as RaceInfo_LOCAL,
} from 'UI/apps/RacingEventsApp/RaceOutcomesPage';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import { getOdds } from 'Services/global/core__american-format';
import { Translations } from '../../../core__racing-events-app';
import project from '../../../../../../project';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import {
  STS as STS_CORE,
  STC as STC_CORE,
  WD,
  CourseMetaImg as CourseMetaImg_CORE,
  RaceInfo as RaceInfo_CORE,
  AdditionalInfo,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesPage';
import { RCP as RCP_CORE } from 'CORE__UI/apps/RacingEventsApp/core__raceCarousel';
import { RCP as RCP_LOCAL } from 'UI/apps/RacingEventsApp/RaceCarousel';
import {
  STC as STC_LOCAL,
  CourseMetaImg as CourseMetaImg_LOCAL,
} from 'UI/apps/RacingEventsApp/RaceOutcomesPage';
import RaceOutcomesHorsesDogs from '../RaceOutcomesHorsesDogs/core__raceOutcomesHorsesDogs';
import { capitalizeFirstLetter } from 'Services/globalfunctions/core__global-functions';
import { getComponent } from 'Services/core__imports';
import { SMRB as SMRB_LOCAL } from 'UI/buttons/RacingButtonStyles';
import { SMRB as SMRB_CORE } from 'CORE__UI/buttons/RacingButton/core__racingButtonStyles';
import {
  RCDC,
  HDEP as HDEP_CORE,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesSelection';
import { HDEP as HDEP_LOCAL } from 'UI/apps/RacingEventsApp/RaceOutcomesSelection';
import {
  getCourseImgSrc,
  getDistanceWithoutZero,
  getInfoBasedOnProvider,
  getSurfaceImgSrc,
  getTimeToStart,
  getWeatherImgSrc,
  images,
  SortIcon,
} from '../../../core__racing-events-utils';
import { FSBCustomerSession, SYMBOLS } from 'Services/core__services';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import RaceOutcomesInfo from './core__raceOutcomesInfo';
import PlaceTermsInfo from './core__placeTermsInfo';
import { Silk } from 'CORE__UI/globals/Silk/core__silk';

const SMRB = getComponent(SMRB_LOCAL, SMRB_CORE);
const STC = getComponent(STC_LOCAL, STC_CORE);
const STS = getComponent(STS_LOCAL, STS_CORE);
const CourseMetaImg = getComponent(CourseMetaImg_LOCAL, CourseMetaImg_CORE);
const RaceInfo = getComponent(RaceInfo_LOCAL, RaceInfo_CORE);
const RCP = getComponent(RCP_LOCAL, RCP_CORE);
const HDEP = getComponent(HDEP_LOCAL, HDEP_CORE);

/**
 * this component dispays race outcomes
 * @param {*} eventData array to get all race outcomes
 * @param {*} getBetslipSelectionList array to get selected bets list
 * * @param {*} requestPage string to get requested page
 * @param {*} oddsFormat string to get odds type
 * */

class RaceOutcomesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllExpendedInfo: {},
      isAllExpended: false,
      currency: project.currency,
      isLoggedIn: false,
      allOutcomesSourceKey: {},
      isMobile: false,
    };
    this.Ref = React.createRef();
    this.handleAllOpened = this.handleAllOpened.bind(this);
    this.handleToggleShowMoreOrLess = this.handleToggleShowMoreOrLess.bind(
      this
    );
  }
  resizeListener = () => {
    const isMobile = window.innerWidth < parseInt(BREAKPOINT_S);
    this.setState({ isMobile: isMobile });
  };
  componentDidMount() {
    PubSub.listen(PubsubEvents.SESSION_CREATE, () => {
      const session = FSBCustomerSession.getSession();
      if (session) {
        this.setState({ currency: session.currencyCode });
        this.setState(prev => ({ ...prev, isLoggedIn: true }));
      }
    });

    const session = FSBCustomerSession.getSession();
    if (session) {
      this.setState({ currency: session.currencyCode });
      this.setState(prev => ({ ...prev, isLoggedIn: true }));
    }

    this.props.showAllRaceOutcomes
      ? this.props.getRaceDetails(
          this.props.raceListInfo,
          '',
          this.props.requestPage,
          '',
          this.props.subscriptionEvents,
          this.props.raceCoursesList
        )
      : this.props.getRaceDetails(
          this.props.raceSourceKey,
          '',
          this.props.requestPage,
          '',
          this.props.subscriptionEvents,
          this.props.raceCoursesList
        );
    const self = this;
    const refreshTime = this.props.appConfig.refreshtime
      ? this.props.appConfig.refreshtime * 1000
      : 30000;
    // Api call at regular interval based on CMS
    this.outerTimer = setTimeout(
      function callRaceListingApi() {
        self.props.showAllRaceOutcomes
          ? self.props.getRaceDetails(
              self.props.raceListInfo,
              self.props.prevStateEventData,
              self.props.requestPage,
              self.props.eventData,
              self.props.subscriptionEvents,
              self.props.raceCoursesList
            )
          : self.props.getRaceDetails(
              self.props.raceSourceKey,
              self.props.prevStateEventData,
              self.props.requestPage,
              self.props.eventData,
              self.props.subscriptionEvents,
              self.props.raceCoursesList
            );
        self.innerTimer = setTimeout(() => {
          callRaceListingApi();
        }, refreshTime);
      }.bind(this),
      refreshTime
    );
    // PubSub event listen to get selections added in betslip
    this.subBetslipUpdate = PubSub.listen(
      PubsubEvents.EventsAppBetslipUpdate,
      wsData => {
        this.props.getBetslipSelections(wsData);
      }
    );
    this.Ref.current && this.Ref.current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      const getScrollId = document.getElementById('activeRaceButton');
      if (getScrollId !== null) {
        getScrollId.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
    }, 500);

    // Pubsub event listen for any update in the session
    this.subSessionUpdated = PubSub.listen(
      PubsubEvents.SESSION_UPDATED,
      sessionInfo => {
        // Function for sending the updated session data of customer
        this.props.flumenCall(
          this.props.raceSourceKey,
          this.props.flumenValue,
          this.props.getFlumenProviders,
          false,
          sessionInfo?.accessToken
        );
      }
    );
    // Pubsub event listen when the session is destroyed
    this.subSessionDestroy = PubSub.listen(PubsubEvents.SESSION_DESTROY, () => {
      // Function for deleting session data of customer
      this.props.sessionData(null);
    });
    // Pubsub event listen when the session is being created
    this.subSessionCreate = PubSub.listen(
      PubsubEvents.SESSION_CREATE,
      sessionInfo => {
        // Function for sending the session data of customer
        this.props.flumenCall(
          this.props.raceSourceKey,
          this.props.flumenValue,
          this.props.getFlumenProviders,
          false,
          sessionInfo?.accessToken
        );
      }
    );
    const isMobile = window.innerWidth < parseInt(BREAKPOINT_S);
    this.setState({ isMobile: isMobile });
    window.addEventListener('resize', this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sessionInfo !== this.props.sessionInfo) {
      if (this.props.sessionInfo) {
        this.setState({ currency: this.props.sessionInfo?.currencyCode });
        this.setState(prev => ({ ...prev, isLoggedIn: true }));
      }
    }
    if (prevProps?.raceSourceKey !== this.props?.raceSourceKey) {
      this.setState({ isAllExpended: false, allOutcomesSourceKey: {} });
    }
    if (this.state.allOutcomesSourceKey !== prevState.allOutcomesSourceKey) {
      let shouldToggle = true;

      Object.keys(this.state.allOutcomesSourceKey).forEach(k => {
        const val = this.state.allOutcomesSourceKey[k];
        if (val === this.state.isAllExpended) {
          shouldToggle = false;
        }
      });
      if (shouldToggle) {
        this.setState({
          isAllExpended: !this.state.isAllExpended,
        });
      }
    }
  }

  // ClearTimeout on component unmount
  componentWillUnmount() {
    clearTimeout(this.outerTimer);
    clearTimeout(this.innerTimer);

    this.subBetslipUpdate?.unsubscribe();
    this.subSessionUpdated?.unsubscribe();
    this.subSessionDestroy?.unsubscribe();
    this.subSessionCreate?.unsubscribe();
    window.removeEventListener('resize', this.resizeListener);
  }

  handleToggleShowMoreOrLess() {
    const isAllExpended = !this.state.isAllExpended;
    const allOutcomesSourceKey = {};
    Object.keys(this.state.allOutcomesSourceKey).forEach(k => {
      allOutcomesSourceKey[k] = isAllExpended;
    });

    this.setState({
      isAllExpended,
      allOutcomesSourceKey,
    });
  }
  handleAllOpened(outcome) {
    this.setState({
      allOutcomesSourceKey: {
        ...this.state.allOutcomesSourceKey,
        [outcome?.sourceKey]: !this.state.allOutcomesSourceKey[
          outcome?.sourceKey
        ],
      },
    });
  }

  handleNewExpandProperties(filteredSelections) {
    // storing all outcomes sourcekey to check it's opened or not providing intial value false
    const allOutcomesSourceKey = {
      ...this.state.allOutcomesSourceKey,
    };
    let isUpdated = false;
    filteredSelections?.forEach(sel => {
      if (
        !Object.prototype.hasOwnProperty.call(
          allOutcomesSourceKey,
          sel?.sourceKey
        )
      ) {
        isUpdated = true;
        allOutcomesSourceKey[sel?.sourceKey] = false;
        allOutcomesSourceKey;
      }
    });
    if (isUpdated) {
      this.setState({
        allOutcomesSourceKey,
      });
    }
  }

  initialSortByOdds(filteredSelections) {
    return (
      filteredSelections &&
      filteredSelections.sort((a, b) => {
        return a.decimalOdds - b.decimalOdds;
      })
    );
  }

  render() {
    const {
      eventData,
      getBetslipSelectionList,
      requestPage,
      oddsFormat,
      showVideoStreamIcon,
      raceSourceKey,
      showAllRaceOutcomes,
      streamicon,
      theme,
      appConfig,
      distanceIcon,
      showSATimeZone,
    } = this.props;

    return (
      <React.Fragment>
        <ED ref={this.Ref}>
          <RaceSelections initialProps={this.props} />{' '}
        </ED>
        {eventData.length > 0 &&
          eventData?.map((eventDetails, eIndex) => {
            return (
              <React.Fragment key={eIndex}>
                <MD>
                  {/* wheather section/handicap/watch */}
                  <HRI>
                    <RIDM>
                      <RID>
                        {showSATimeZone
                          ? moment(eventDetails?.scheduledStart).format(
                              project.timeFormats.racing.quaternary || 'HH:mm'
                            )
                          : moment(eventDetails?.scheduledStart).hours() > 12
                          ? moment(eventDetails?.scheduledStart).format(
                              project.timeFormats.racing.quaternary || 'HH:mm'
                            )
                          : eventDetails?.name}{' '}
                        {eventDetails?.raceCourseName}
                        {appConfig?.showtimeremains &&
                          eventDetails?.scheduledStart &&
                          this.state.isLoggedIn &&
                          !this.state.isMobile && (
                            <WB info={true}>
                              <STC noBg={true}>
                                <div>
                                  {getTimeToStart(
                                    eventDetails.scheduledStart,
                                    Translations
                                  )}
                                </div>
                              </STC>
                            </WB>
                          )}
                      </RID>
                      <RST>
                        {appConfig?.showextrainfo &&
                          !appConfig?.showtimeremains &&
                          eventDetails?.scheduledStart &&
                          this.state.isLoggedIn && (
                            <WB info={true}>
                              <STC>
                                <div>
                                  {getTimeToStart(
                                    eventDetails.scheduledStart,
                                    Translations
                                  )}
                                </div>
                              </STC>
                            </WB>
                          )}
                        {(eventDetails.state !== GLOBAL_CONSTANTS.COMPLETED ||
                          !Object.prototype.hasOwnProperty.call(
                            eventDetails,
                            'results'
                          )) &&
                          appConfig.displaystreamifavailable &&
                          eventDetails.status !== 'A' &&
                          eventDetails?.flumenProviders?.length > 0 && (
                            <>
                              {this.state.isLoggedIn ? (
                                <WB
                                  data-test="streamicon"
                                  onClick={() => {
                                    this.props.hideAndShowStreamIcon(
                                      showVideoStreamIcon,
                                      raceSourceKey,
                                      eventDetails?.providerRef,
                                      eventDetails?.flumenProviders
                                    );
                                  }}
                                >
                                  {(streamicon && (
                                    <STC>
                                      <I
                                        iconName={getIcon('STREAMING')}
                                        size={appConfig?.iconsize || 28}
                                      />
                                      <STS>
                                        {showVideoStreamIcon
                                          ? Translations.get('racing.hide')
                                          : appConfig?.showextrainfo
                                          ? getInfoBasedOnProvider(
                                              this.state.isLoggedIn,
                                              eventDetails.providerRef,
                                              eventDetails.name,
                                              Translations
                                            )
                                          : Translations.get('racing.watch')}
                                      </STS>
                                    </STC>
                                  )) || (
                                    <SO>
                                      <ST>
                                        {appConfig?.showextrainfo
                                          ? getInfoBasedOnProvider(
                                              this.state.isLoggedIn,
                                              eventDetails.providerRef,
                                              eventDetails.name,
                                              Translations
                                            )
                                          : Translations.get('racing.watch')}
                                      </ST>
                                    </SO>
                                  )}
                                </WB>
                              ) : (
                                <SO>
                                  <ST>
                                    {Translations.get('racing.login.towatch')}
                                  </ST>
                                </SO>
                              )}
                            </>
                          )}
                        {eventDetails.status === 'O' &&
                        (eventDetails.finished === 'false' ||
                          !eventDetails.forecastWinValue) &&
                        eventDetails.progressCode === 'O' ? (
                          <RIR>{Translations.get('racing.started')}</RIR>
                        ) : eventDetails.status === 'A' ? (
                          <RA>{Translations.get('racing.abandoned')}</RA>
                        ) : (
                          eventDetails.state === GLOBAL_CONSTANTS.COMPLETED &&
                          Object.prototype.hasOwnProperty.call(
                            eventDetails,
                            'results'
                          ) && <RS>{Translations.get('racing.results')}</RS>
                        )}
                      </RST>
                    </RIDM>
                    {appConfig?.showtimeremains &&
                      eventDetails?.scheduledStart &&
                      this.state.isLoggedIn &&
                      this.state.isMobile && (
                        <WB info={true} isMobile>
                          <STC noBg={true}>
                            <div>
                              {getTimeToStart(
                                eventDetails.scheduledStart,
                                Translations
                              )}
                            </div>
                          </STC>
                        </WB>
                      )}
                    {eventDetails?.title && <RP>{eventDetails?.title}</RP>}
                    {this.state.isMobile && (
                      <RaceOutcomesInfo
                        eventDetails={eventDetails}
                        appConfig={appConfig}
                      />
                    )}
                    {appConfig?.showextrainfo ? (
                      <IU>
                        <AdditionalInfo>
                          {eventDetails.weather &&
                            eventDetails?.weather?.trim() !== '' && (
                              <IL>
                                <CourseMetaImg
                                  src={getWeatherImgSrc(eventDetails.weather)}
                                />
                                <p>{eventDetails?.weather}</p>
                              </IL>
                            )}
                          {eventDetails?.distance && (
                            <IL>
                              <CourseMetaImg
                                src={images.DISTANCE}
                                distanceIcon={distanceIcon}
                              />
                              <p>
                                {getDistanceWithoutZero(eventDetails.distance)}
                              </p>
                            </IL>
                          )}
                          {requestPage === GLOBAL_CONSTANTS.DOGS &&
                            eventDetails?.grade && (
                              <IL>
                                <b>{Translations.get('racing.grade')} </b>
                                <p>{eventDetails?.grade}</p>
                              </IL>
                            )}
                          {eventDetails?.going && (
                            <IL>
                              <CourseMetaImg
                                src={getSurfaceImgSrc(eventDetails.surface)}
                              />
                              <p>{eventDetails?.going}</p>
                            </IL>
                          )}
                          {eventDetails?.courseDirection && (
                            <IL>
                              <CourseMetaImg
                                src={getCourseImgSrc(
                                  eventDetails.courseDirection
                                )}
                              />
                              <p>{eventDetails.courseDirection}</p>
                            </IL>
                          )}
                        </AdditionalInfo>
                        {!this.state.isMobile && (
                          <RaceOutcomesInfo
                            eventDetails={eventDetails}
                            appConfig={appConfig}
                          />
                        )}
                      </IU>
                    ) : (
                      <IU>
                        <AdditionalInfo>
                          {(eventDetails?.runners ||
                            eventDetails?.runners === 0) && (
                            <IL>
                              <b>{Translations.get('racing.runners')} </b>
                              <p>{eventDetails?.runners}</p>
                            </IL>
                          )}
                          {eventDetails?.distance && (
                            <IL>
                              <b>{Translations.get('racing.distance')} </b>
                              <p>{eventDetails?.distance}</p>
                            </IL>
                          )}
                          {requestPage === GLOBAL_CONSTANTS.DOGS &&
                            eventDetails?.grade && (
                              <IL>
                                <b>{Translations.get('racing.grade')} </b>
                                <p>{eventDetails?.grade}</p>
                              </IL>
                            )}
                          {eventDetails?.going && (
                            <IL>
                              <b>{Translations.get('racing.going')} </b>
                              <p>{eventDetails?.going}</p>
                            </IL>
                          )}
                        </AdditionalInfo>
                        <RaceInfo>
                          <IL>
                            <p>
                              {eventDetails?.handicap && (
                                <span>
                                  {Translations.get('racing.handicap')}
                                  {', '}
                                </span>
                              )}
                              {eventDetails?.prizePosition1 && (
                                <span>
                                  <span>{SYMBOLS[this.state.currency]}</span>
                                  {eventDetails?.prizePosition1} (1st)
                                </span>
                              )}{' '}
                            </p>
                          </IL>
                          {eventDetails?.rule4 && (
                            <IL>
                              <p>
                                {appConfig?.istranslated
                                  ? Translations.get('racing.rule4.may.apply')
                                  : eventDetails?.rule4}
                              </p>
                            </IL>
                          )}
                          {eventDetails?.antePost && (
                            <IL>
                              <p>
                                {appConfig?.istranslated
                                  ? Translations.get(
                                      'racing.ante.post.rules.apply'
                                    )
                                  : eventDetails?.antePost}
                              </p>
                            </IL>
                          )}
                        </RaceInfo>
                      </IU>
                    )}
                  </HRI>

                  {showVideoStreamIcon &&
                    !Object.prototype.hasOwnProperty.call(
                      eventDetails,
                      'results'
                    ) && (
                      <div>
                        <RaceStream
                          eventDetails={eventDetails}
                          icon={appConfig?.streamingicon}
                        />
                      </div>
                    )}
                  {/* TABS */}
                  {appConfig.showextraplacesui &&
                    eventDetails &&
                    eventDetails.market &&
                    eventDetails?.market?.some(
                      market =>
                        market?.book &&
                        market?.book?.some(
                          book =>
                            book?.placeTermsType === 'MANUAL' &&
                            market.typeRef === 'FPTP'
                        )
                    ) && (
                      <HDEP onClick={this.handleToggleExtraPlace}>
                        <RCDC>
                          <RCP />
                          <div>
                            <b>
                              {
                                Translations.get('race.extra.place').split(
                                  ':'
                                )[0]
                              }
                            </b>
                            {Translations.get('race.extra.place').substring(
                              Translations.get('race.extra.place').indexOf(':')
                            )}{' '}
                            {eventDetails.market[0].book[0].places}{' '}
                            {Translations.get(
                              'race.extra.place.odds'
                            ).substring(
                              0,
                              Translations.get('race.extra.place.odds').indexOf(
                                '/'
                              ) + 1
                            )}
                            {eventDetails.market[0].book[0].fraction}{' '}
                            {Translations.get(
                              'race.extra.place.odds'
                            ).substring(
                              Translations.get('race.extra.place.odds').indexOf(
                                '/'
                              ) + 1
                            )}
                          </div>
                        </RCDC>
                      </HDEP>
                    )}

                  <EDB>
                    <RaceMarkets
                      eventDetails={eventDetails}
                      eIndex={eIndex}
                      isTranslated={appConfig?.jptranslation}
                      showDogsMarketNameAsRacecard={
                        appConfig?.showdogsmarketnameasracecard
                      }
                    />
                  </EDB>
                  {(eventDetails.state !== GLOBAL_CONSTANTS.COMPLETED ||
                    !Object.prototype.hasOwnProperty.call(
                      eventDetails,
                      'results'
                    )) &&
                  eventDetails.status !== 'A'
                    ? eventDetails?.market.length > 0 && (
                        <React.Fragment>
                          <HM>
                            {eventDetails.state !==
                              GLOBAL_CONSTANTS.COMPLETED &&
                              !Object.prototype.hasOwnProperty.call(
                                eventDetails,
                                'results'
                              ) && (
                                <React.Fragment>
                                  <OT
                                    data-test="label-sortfilter"
                                    onClick={() => {
                                      this.props.changeSortFilter(
                                        eventData,
                                        eventDetails.sortBy,
                                        eIndex,
                                        showAllRaceOutcomes
                                      );
                                    }}
                                  >
                                    <SortIcon theme={appConfig?.theme} />
                                    &nbsp;&nbsp;
                                    {eventDetails?.sortBy &&
                                      (appConfig?.istranslated
                                        ? Translations.get(
                                            'racing.sort.' +
                                              eventDetails.sortBy
                                                .toLowerCase()
                                                .replaceAll(' ', '')
                                          )
                                        : capitalizeFirstLetter(
                                            eventDetails?.sortBy.toLowerCase()
                                          ))}
                                  </OT>
                                </React.Fragment>
                              )}
                            {appConfig?.isadditionalinfoforhorses && (
                              <SMRB onClick={this.handleToggleShowMoreOrLess}>
                                {this.state.isAllExpended
                                  ? Translations.get('text.hide.info')
                                  : Translations.get('text.show.info')}
                              </SMRB>
                            )}
                            <PlaceTermsInfo
                              eventDetails={eventDetails}
                              appConfig={appConfig}
                            />
                          </HM>
                          {eventDetails?.market.map(marketName => {
                            let filteredSelections =
                              marketName?.name ===
                                eventDetails.selectedRaceMarket &&
                              marketName?.selection?.filter(
                                outcomes =>
                                  !outcomes.nonRunner &&
                                  outcomes.favSelectionDisable !==
                                    'hideFavSelection'
                              );

                            filteredSelections &&
                              this.handleNewExpandProperties(
                                filteredSelections
                              );

                            if (
                              eventDetails?.sortBy === 'ODDS' &&
                              this.props.appConfig?.initialsortingbyodds
                            )
                              filteredSelections =
                                filteredSelections &&
                                this.initialSortByOdds(filteredSelections);

                            return (
                              filteredSelections &&
                              filteredSelections.map((outcomes, oIndex) => {
                                return (
                                  <RaceOutcomesHorsesDogs
                                    appConfig={appConfig}
                                    handleAllOpened={this.handleAllOpened}
                                    eventDetails={eventDetails}
                                    oIndex={oIndex}
                                    theme={theme}
                                    marketName={marketName}
                                    outcomes={outcomes}
                                    oddsFormat={oddsFormat}
                                    callBetslip={this.props.callBetslip}
                                    getBetslipSelectionList={
                                      getBetslipSelectionList
                                    }
                                    requestPage={requestPage}
                                    key={oIndex}
                                    isAllExpended={
                                      this.state.isAllExpendedInfo[
                                        eventDetails?.id
                                      ]
                                    }
                                    isExpanded={
                                      this.state.allOutcomesSourceKey[
                                        outcomes?.sourceKey
                                      ]
                                    }
                                  />
                                );
                              })
                            );
                          })}
                        </React.Fragment>
                      )
                    : eventDetails?.market.length > 0 &&
                      eventDetails?.market.map(marketName => {
                        return (
                          marketName?.name ===
                            eventDetails.selectedRaceMarket &&
                          marketName?.selection?.map((outcomes, oIndex) => {
                            const { silkSvg, silk } = outcomes;
                            const imageUrl = silkSvg || silk;

                            return (
                              <React.Fragment key={oIndex}>
                                {outcomes.position && (
                                  <MI>
                                    <TI>
                                      <TII>
                                        {outcomes.position}
                                        {outcomes?.winningDistance && (
                                          <WD>{outcomes?.winningDistance}</WD>
                                        )}
                                      </TII>
                                      {requestPage !== GLOBAL_CONSTANTS.DOGS ? (
                                        <TII>
                                          {imageUrl && (
                                            <Silk
                                              src={
                                                project.racingSilksUrl
                                                  ? `${project.racingSilksUrl}${imageUrl}`
                                                  : `https://silks.fsbtech.com/${imageUrl}`
                                              }
                                              alt={`${outcomes.name} racing colours`}
                                            />
                                          )}
                                        </TII>
                                      ) : (
                                        outcomes.drawn &&
                                        outcomes.drawn !== 'null' && (
                                          <TBB
                                            positionStyle={
                                              drawnPosition &&
                                              drawnPosition[
                                                '__' + outcomes.drawn
                                              ]
                                            }
                                            apptheme={theme}
                                          >
                                            {outcomes.drawn}
                                          </TBB>
                                        )
                                      )}
                                      <ED>
                                        {requestPage !==
                                        GLOBAL_CONSTANTS.DOGS ? (
                                          <TIH>
                                            {outcomes.ordinal &&
                                              outcomes.ordinal !== 'null' &&
                                              outcomes.ordinal}{' '}
                                            {outcomes.name}
                                          </TIH>
                                        ) : (
                                          <TIH>{outcomes.name}</TIH>
                                        )}
                                        {outcomes?.trainer && (
                                          <TIJ>T: {outcomes.trainer}</TIJ>
                                        )}
                                        {outcomes?.jockey && (
                                          <TIJ>J: {outcomes.jockey}</TIJ>
                                        )}
                                      </ED>
                                    </TI>
                                    <OI>
                                      <OIO>
                                        <OIF>
                                          {oddsFormat?.toLowerCase() ===
                                          GLOBAL_CONSTANTS.DECIMAL
                                            ? parseDecimalPlaces(
                                                outcomes.decimalOdds
                                              )
                                            : oddsFormat?.toLowerCase() ===
                                              GLOBAL_CONSTANTS.FRACTIONAL
                                            ? outcomes.fractionalOdds
                                            : outcomes.decimalOdds !==
                                              GLOBAL_CONSTANTS.SP
                                            ? getOdds(
                                                outcomes.decimalOdds,
                                                true
                                              )
                                            : outcomes.decimalOdds}
                                        </OIF>
                                        <br />
                                        {Number(outcomes.decimalOdds) ===
                                          Number(
                                            marketName.lowestSpDecOdds
                                          ) && (
                                          <OIF>{marketName.favouriteHorse}</OIF>
                                        )}
                                      </OIO>
                                    </OI>
                                  </MI>
                                )}
                              </React.Fragment>
                            );
                          })
                        );
                      })}
                </MD>
                <MD>
                  <RaceFavourite eventDetails={eventDetails} />
                </MD>
                <MD>
                  <RaceNR eventDetails={eventDetails} theme={theme} />
                </MD>
              </React.Fragment>
            );
          })}
      </React.Fragment>
    );
  }
}

RaceOutcomesPage.propTypes = {
  getRaceDetails: PropTypes.func,
  changeSortFilter: PropTypes.func,
  eventData: PropTypes.array,
  getBetslipSelectionList: PropTypes.array,
  callBetslip: PropTypes.func,
  getBetslipSelections: PropTypes.func,
  requestPage: PropTypes.string,
  raceSourceKey: PropTypes.string,
  oddsFormat: PropTypes.string,
  subscriptionEvents: PropTypes.array,
  raceCoursesList: PropTypes.array,
  showVideoStreamIcon: PropTypes.bool,
  hideAndShowStreamIcon: PropTypes.func,
  flumenCall: PropTypes.func,
  sessionData: PropTypes.func,
  flumenValue: PropTypes.string,
  getFlumenProviders: PropTypes.array,
  showAllRaceOutcomes: PropTypes.bool,
  streamicon: PropTypes.bool,
  theme: PropTypes.string,
  raceListInfo: PropTypes.array,
  appConfig: PropTypes.object,
  distanceIcon: PropTypes.bool,
  showSATimeZone: PropTypes.bool,
  sessionInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RaceOutcomesPage.defaultProps = {
  eventData: [],
  getBetslipSelectionList: [],
  raceSourceKey: '',
  requestPage: '',
  oddsFormat: '',
  subscriptionEvents: [],
  raceCoursesList: [],
  showVideoStreamIcon: false,
  flumenValue: '',
  getFlumenProviders: [],
  showAllRaceOutcomes: false,
  streamicon: true,
  raceListInfo: [],
};
export default RaceOutcomesPage;
