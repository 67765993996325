import {
  BSSMD,
  BI,
  BetslipContentBetBoxPot,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__stake';
import styled from 'styled-components';
import { BK_COLORS } from 'UI/globals/colours';

const BI_QN = styled(BI)`
  background: transparent;
  padding: 14px 8px;
  font-size: 14px;
`;

const BSSMD_QN = styled(BSSMD)`
  background: ${BK_COLORS.aliceBlue08};
  border: none;
  input::placeholder {
    color: #666666;
  }
`;
export { BSSMD_QN as BSSMD, BI_QN as BI, BetslipContentBetBoxPot };
