import defaultConstants from '../../../project';

export const Constants = {
  MULTIPLE: 'multiple',
  SINGLE_C: 'Single',
  SINGLE: 'single',
  SYSTEM: 'system',
  FORCAST: 'Forecast',
  FORCASTTricast: 'Forecast/Tricast',
  MULTIPLES: 'Multiples',
  HORSES: 'HORSES',
  DOGS: 'DOGS',
  VIRTUAL: 'VIRTUAL',
  DEFAULT_AMT: 0.0,
  TAXED_CATEGORIES: ['HORSES'],
  N_A: 'N/A',
  DEFAULT_MODIFIERBETCOUNT: 1,
  LSKEY_UK: 'betslip_uk',
  LSKEY_US: 'betslip_us',
  TRICAST: 'Tricast',
  SP: 'SP',
  Deletesuspended: 'deletesuspended',
  DECIMAL: 'decimal',
  DEPOSITLABEL: 'deposit',
  FRACTIONAL: 'fractional',
  STATICICONPATH: `${defaultConstants.staticPath.basePath}${defaultConstants.staticPath.bucketReact}${defaultConstants.staticPath.folderIcon}`,
  STATICMISCPATH: `${defaultConstants.staticPath.basePath}${defaultConstants.staticPath.bucketImages}${defaultConstants.staticPath.folderMisc}`,
  PLACEBET: 'text.place.bet',
  PLACEBETLABEL: 'placebetLabel',
  LOGINANDPLACEBET: 'text.login.place.bet',
  LOGINANDPLACEBETLABEL: 'loginPlacebet',
  DEPOSITANDPLACEBETLABEL: 'depositPlacebet',
  ACCEPTSODDS: 'text.accept.odd.changes',
  ACCEPTODDSLABEL: 'acceptOdds',
  REMOVESUSPENDED: 'text.remove.suspended',
  TRN: 'text.trn',
  FEE: 'text.fee',
  TRG: 'text.trg',
  TS: 'text.ts',
  TFS: 'text.tfs',
  PR: 'Potential Returns',
  INC: 'increase',
  DEC: 'decrease',
  AMERICAN: 'american',
  PRS: 'text.pt.rtn',
  BBB: 'BBB',
  ODDMOMENT: 'odd-moment',
  TOTALRETURN: 'text.total.return',
  BET: 'bet',
  MYBET: 'mybet',
  SGL: 'SGL',
  INNER_Y: 'INNER_Y',
  BETSLIP_OPEN: 'BETSLIP_OPEN',
  IFRAME_SIZE: 'IFRAME_SIZE',
  OPEN_BETSLIP: 'OPEN_BETSLIP',
  BODY: 'BODY',
  INPUT_FOCUS: 'INPUT_FOCUS',
  COLOR: 'white',
  BONUS_AMOUNT: 'text.bonus.amount',
  CAST: 'CAST',
  SCT: 'SCT',
  HHMM: '\\d+\\:\\d+',
  BUILDERUSEDATA: 'buideruseData',
  DEFAULT: 'default',
  BLOCK: 'block',
  LOWER: 'lower',
  HIGHER: 'higher',
  REQUESTED: 'REQUESTED',
  CURRENT: 'CURRENT',
  HIGHER_C: 'HIGHER',
  LOWER_C: 'LOWER',
  TOP: 'top',
  BOTTOM: 'bottom',
  EACHWAY: 'EACHWAY',
  BASKETBALL_REF: 'BASKETBALL',
  SOCCER_REF: 'SOCCER',
  ICE_HOCKEY_REF: 'ICE_HOCKEY',
  EMPTYSTRING: '',
  FREEBETBYTWO: '÷2',
  MAX: 'max',
  MIN: 'min',
  MAXSTAKE: 'stake exceed',
  UPDATE: 'Update',
  SINGLEMINSTAKE: 'SINGLE_MIN_STAKE',
  MULTIPLEMINSTAKE: 'MULTIPLE_MIN_STAKE',
  TENNIS_REF: 'TENNIS',
  DOUBLE: 'Double',
  DBL: 'DBL',
  CRICKET_REF: 'CRICKET',
  RUGBY_LEAGUE_REF: 'RUGBY_LEAGUE',
  RUGBY_UNION_REF: 'RUGBY_UNION',
  TABLE_TENNIS_REF: 'TABLE_TENNIS',
  AMERICAN_FOOTBALL_REF: 'AMERICAN_FOOTBALL',
  SNOOKER_REF: 'SNOOKER',
  DARTS_REF: 'DARTS',
  HANDBALL_REF: 'HANDBALL',
  ESPORTS_REF: 'ESPORTS',
  VOLLEYBALL_REF: 'VOLLEYBALL',
  AUSTRALIAN_RULES_REF: 'AUSTRALIAN_RULES',
  BASEBALL_REF: 'BASEBALL',
  WATERPOLO: 'WATERPOLO',
  PRICE_NOT_CURRENT_ERROR_CODE: 'api.error.bet.price.not.current',
  PRICE_CHANGE_ERROR_CODE: 'api.error.bet.price.change',
};
