import RaceListingPage from './core__raceListingPage';
import React from 'react';

const RaceListingPageCopy = props => {
  return (
    <RaceListingPage
      {...props}
      isBackgroundChange={true}
      showSATimeZone={true}
    />
  );
};

export default RaceListingPageCopy;
