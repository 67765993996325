import {
  WSPOPUP,
  WSP,
  WSUL,
  WSLI1BTN,
  WSLI2BTN,
  TDIV,
  TLI,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__socketMessage';
import styled from 'styled-components';
import { BK_COLORS, FONT } from '../../../globals/colours';

const WSPOPUP_QN = styled(WSPOPUP)`
  background-color: ${BK_COLORS?.aliceBlue08};
  border: 1px ${BK_COLORS?.aliceBlue08};
  border-left: 1px solid ${BK_COLORS?.aliceBlue08};
  border-right: 1px solid ${BK_COLORS?.aliceBlue08};
  padding: 8px 0;
  height: auto;
`;
const WSP_QN = styled(WSP)`
  padding-top: 0;
`;
const TDIV_QN = styled(TDIV)`
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid ${BK_COLORS?.aliceBlue08};
`;
const WSLI2BTN_QN = styled(WSLI2BTN)`
  border-radius: 99px;
  background: ${BK_COLORS?.blue};
  color: ${FONT.light};
  margin-bottom: 0;
`;

const WSLI1BTN_QN = styled(WSLI1BTN)`
  border-radius: 99px;
  background: ${BK_COLORS?.blue};
  color: ${FONT.light};
  margin-bottom: 0;
`;
export {
  WSPOPUP_QN as WSPOPUP,
  WSP_QN as WSP,
  WSUL,
  WSLI1BTN_QN as WSLI1BTN,
  WSLI2BTN_QN as WSLI2BTN,
  TDIV_QN as TDIV,
  TLI,
};
