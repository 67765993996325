import styled from 'styled-components';
import {
  MD,
  RP,
  RIDM,
  RID,
  RIR,
  IU,
  IL,
  MI,
  TI,
  HI,
  OI,
  TIH,
  TIJ,
  TII,
  TBB,
  HIF,
  HIA,
  OIO,
  ED,
  EDB,
  RS,
  RA,
  OIF,
  HRI,
  RST,
  TLD,
  HIAP,
  OB,
  H,
  HR,
  HD,
  HM,
  HRB,
  HV,
  WB,
  OT,
  OMD,
  ST,
  SO,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesPage';
import { GREYS, BK_COLORS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const MI_EX = styled(MI)`
  border: none;
  border-bottom: 1px solid ${BK_COLORS.aliceBlue10};
`;

const EDB_EX = styled(EDB)`
  border-top: 1px solid ${BK_COLORS.aliceBlue10};
  border-bottom: 1px solid ${BK_COLORS.aliceBlue10};
`;

const HM_EX = styled(HM)`
  border-bottom: 1px solid ${BK_COLORS.aliceBlue10};
`;

const HRB_EX = styled(HRB)`
  color: ${GREYS.black};
`;

const HRI_EX = styled(HRI)`
  border-color: ${BK_COLORS.aliceBlue10};
  &:last-of-type {
    border-bottom: none;
    padding-left: 10px;
    cursor: pointer;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    &:last-of-type {
      padding-left: 4px;
    }
  }
`;

const HI_EX = styled(HI)`
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
  `;
const TIJ_EX = styled(TIJ)`
  font-size: 12px;
  letter-spacing: 0.25px;
`;

const HIAP_EX = styled(HIAP)`
  font-size: 12px;
  letter-spacing: 0.25px;
`;

const OIO_EX = styled(OIO)`
  font-size: 12px;
  letter-spacing: 0.25px;
`;

const MD_EX = styled(MD)`
  box-shadow: none;
  @media (max-width: ${BREAKPOINT_S}) {
    border-radius: 0;
  }
`;

const OT_EX = styled(OT)`
  & span:after {
    border-color: ${GREYS.black};
  }
`;

const RID_EX = styled(RID)`
  color: ${BK_COLORS.blue};
`;

export {
  MD_EX as MD,
  RP,
  RIDM,
  RID_EX as RID,
  RIR,
  IU,
  IL,
  MI_EX as MI,
  TI,
  HI_EX as HI,
  OI,
  TIH,
  TIJ_EX as TIJ,
  TII,
  TBB,
  HIF,
  HIA,
  OIO_EX as OIO,
  ED,
  EDB_EX as EDB,
  RS,
  RA,
  OIF,
  HRI_EX as HRI,
  RST,
  TLD,
  HIAP_EX as HIAP,
  OB,
  H,
  HR,
  HD,
  HM_EX as HM,
  HRB_EX as HRB,
  HV,
  WB,
  OT_EX as OT,
  OMD,
  ST,
  SO,
};
