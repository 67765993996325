import {
  RB,
  SRB,
  RC,
  TV,
  EP,
  HRB,
  SMRBD,
} from 'CORE__UI/buttons/RacingButton/core__racingButtonStyles';
import styled from 'styled-components';
import { FONT, BK_COLORS } from 'UI/globals/colours';

const RB_QN = styled(RB)`
  border-color: transparent;
  color: ${FONT.black.label};
  background-color: ${BK_COLORS.aliceBlue};
  &:hover {
    border-color: ${BK_COLORS.blue};
    color: ${BK_COLORS.darkblue};
    background: ${BK_COLORS.EVENTS.gradient};
    font-weight: 800;
  }
`;

const SRB_QN = styled(SRB)`
  border-color: ${BK_COLORS.blue};
  color: ${BK_COLORS.darkblue};
  background: ${BK_COLORS.EVENTS.gradient};
  font-weight: 800;
  &:hover {
    background: ${BK_COLORS.EVENTS.gradient};
  }
`;

const HRB_QN = styled(HRB)`
  border-color: transparent;
  color: ${FONT.black.disabled};
  background-color: ${BK_COLORS.aliceBlue};
  &:hover {
    border-color: ${BK_COLORS.blue};
    color: ${BK_COLORS.darkblue};
    background: ${BK_COLORS.EVENTS.gradient};
    font-weight: 800;
  }
`;

const TV_QN = styled(TV)`
  margin: 2px 0px 0px 4px;
`;

const SMRBD_EXT = styled(SMRBD)`
  color: ${FONT.blue};
`;

export {
  RB_QN as RB,
  SRB_QN as SRB,
  RC,
  TV_QN as TV,
  EP,
  HRB_QN as HRB,
  SMRBD_EXT as SMRBD,
};
