import styled from 'styled-components';
import {
  TBB,
  TBD,
  TBS,
  TBT,
  TBTC,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__taxBottomSection';
import { BK_COLORS, GREYS, FONT } from 'UI/globals/colours';

const TBS_QN = styled(TBS)`
  background: ${BK_COLORS.aliceBlue};
`;

const TBTC_QN = styled(TBTC)`
  color: ${GREYS.black || FONT.black.standard};
`;

const TBT_QN = styled(TBT)`
  color: ${GREYS.black || FONT.black.standard};
`;

const TBB_QN = styled(TBB)`
  border: 1px solid ${BK_COLORS.aliceBlue10};
  color: ${GREYS.black};
`;

export { TBB_QN as TBB, TBD, TBS_QN as TBS, TBTC_QN as TBTC, TBT_QN as TBT };
