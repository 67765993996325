import {
  QLM,
  QLMH4,
  QLUL,
  QLLI,
  QLA,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__quickLink';
import styled from 'styled-components';
import { BK_COLORS, FONT, GREYS } from '../../../globals/colours';

const QLAQN = styled(QLA)`
  background-color: ${BK_COLORS?.aliceBlue08 || GREYS.dove};
  color: ${FONT.dark};
  letter-spacing: 0.25px;
  border-radius: 99px;
  padding: 17px 24px;
`;

const QLM_JN = styled(QLM)`
  border-top: 1px solid ${GREYS.doveD};
  padding: 0 20px;
  margin: 0;
`;
const QLMH4_QN = styled(QLMH4)`
  font-size: 18px;
  margin-top: 24px;
  color: ${FONT.dark};
`;

export { QLM_JN as QLM, QLMH4_QN as QLMH4, QLUL, QLLI, QLAQN as QLA };
