import {
  ADIV,
  AS,
  AD,
  Ah3,
  Apdate,
  C3,
  C31,
  CL,
  Ch6,
  Ch5,
} from 'CORE__UI/apps/BetslipUKApp/ReceiptContainer/core__topSection';
import styled from 'styled-components';
import { GREYS } from '../../../globals/colours';

const ADIV_QN = styled(ADIV)`
  color: ${GREYS.black};
`;

const Ah3_QN = styled(Ah3)`
  color: ${GREYS.black};
`;
export {
  ADIV_QN as ADIV,
  AS,
  AD,
  Ah3_QN as Ah3,
  Apdate,
  C3,
  C31,
  CL,
  Ch6,
  Ch5,
};
