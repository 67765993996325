import {
  SB,
  BIT,
  BA,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__tooltip';
import styled from 'styled-components';
import { GREYS } from '../../../globals/colours';

const BIT_QN = styled(BIT)`
  background-color: ${GREYS.dove};
`;

export { SB, BIT_QN as BIT, BA };
