import { BL, BH } from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__name';
import { BK_COLORS } from 'UI/globals/colours';
import styled, { css } from 'styled-components';

const BH_QN = styled(BH)`
  ${({ isSGL }) =>
    !isSGL &&
    css`
      color: ${BK_COLORS?.blue};
    `}
`;

export { BL, BH_QN as BH };
