import { PT, BA, Wrap } from 'CORE__UI/globals/PageTitle/core__pageTitleStyles';
import styled, { css } from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import { FONT } from './colours';

const PT_EX = styled(PT)`
  color: ${FONT.black.label};
  box-shadow: none;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-top: 0;
    border-radius: 0;
    font-size: 14px;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    ${({ leftPadding }) =>
      leftPadding &&
      css`
        padding-left: 20%;
      `}
  }
`;

const Wrap_EX = styled(Wrap)`
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 0;
  }
`;

const BA_EX = styled(BA)`
  padding-left: 16px;
`;

export { PT_EX as PT, BA_EX as BA, Wrap_EX as Wrap };
