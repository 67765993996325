import { BBS } from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__booster';
import styled from 'styled-components';
import { BK_COLORS, GREYS } from 'UI/globals/colours';

const BBS_QN = styled(BBS)`
  button {
    border-radius: 99px;
    background-color: ${props =>
      props?.bbFlag ? 'transparent' : BK_COLORS.blue};
    color: ${GREYS.black};
    border: 1px solid
      ${props => (props?.bbFlag ? BK_COLORS.aliceBlue10 : BK_COLORS.blue)};
    &:hover {
      background-color: ${props =>
        props?.bbFlag ? 'transparent' : BK_COLORS.blue};
      color: ${GREYS.black};
      border: 1px solid
        ${props => (props?.bbFlag ? BK_COLORS.aliceBlue10 : BK_COLORS.blue)};
    }
    &:focus {
      background-color: ${props =>
        props?.bbFlag ? 'transparent' : BK_COLORS.blue};
      color: ${GREYS.black};
      border: 1px solid
        ${props => (props?.bbFlag ? BK_COLORS.aliceBlue10 : BK_COLORS.blue)};
    }
    & i {
      color: ${BK_COLORS.yellow};
    }
  }
`;
export { BBS_QN as BBS };
