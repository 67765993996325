import {
  BCBD,
  BCBC,
  BCBS,
  L,
  S,
  T,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__bonus';

import styled, { css } from 'styled-components';
import { BRAND, FONT, GREYS } from '../../../globals/colours';

const SQN = styled(S)`
  margin-left: 4px;
  background-color: ${GREYS.white};
  ${({ dc }) =>
    dc &&
    css`
      :after {
        border: solid ${BRAND.primary};
        background-color: ${GREYS.white};
        border-width: 0 4px 4px 0;
      }
    `}
`;

const LQN = styled(L)`
  color: ${FONT.dark};
  input:checked ~ ${SQN} {
    background-color: ${GREYS.white};
    border: 1px solid ${BRAND.primary};
  }
`;

export { BCBD, BCBC, BCBS, LQN as L, SQN as S, T };
