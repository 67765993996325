import {
  BCFR,
  BCFWC,
  BCWCH,
  BCFSS,
  CONF,
  CAN,
  BCFBTH,
  MStyles,
  IRDO,
  FBI,
  BC,
  X,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__freebet';
import styled from 'styled-components';
import { BK_COLORS } from 'UI/globals/colours';

const BCFR_QN = styled(BCFR)`
  background-color: ${BK_COLORS.red};
  height: 44px;
  max-width: 32px;
  justify-content: center;
`;
export {
  BCFR_QN as BCFR,
  BCFWC,
  BCWCH,
  BCFSS,
  CONF,
  CAN,
  BCFBTH,
  MStyles,
  IRDO,
  FBI,
  BC,
  X,
};
