import {
  DChekBx,
  DS,
  DBTN,
  D,
  Ch5,
  CL,
  C,
} from 'CORE__UI/apps/BetslipUKApp/ReceiptContainer/core__bottomSection';
import styled from 'styled-components';
import { BK_COLORS, GREYS } from 'UI/globals/colours';

const DBTN_QN = styled(DBTN)`
  background-color: ${BK_COLORS?.blue};
  color: ${GREYS.white};
  border-radius: 99px;
  border-color: ${BK_COLORS?.blue};
  height: 40px;
  cursor: pointer;
`;

export { DChekBx, DS, DBTN_QN as DBTN, D, Ch5, CL, C };
