import {
  SBSD,
  SBSU,
  SBSL,
  SBSLH6,
  SBSLH6B,
  SBSNPB,
  SBSPB,
  SpinnerObj,
  BSB,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__bottomSection';
import styled from 'styled-components';
import { GREYS, BRAND, BK_COLORS, FONT } from 'UI/globals/colours';

const SBSD_QN = styled(SBSD)`
  background-color: ${BK_COLORS.aliceBlue || GREYS.black};
  border-color: transparent;
`;

const SBSLH6_QN = styled(SBSLH6)`
  color: ${GREYS.black};
`;

const SBSLH6B_QN = styled(SBSLH6B)`
  color: ${GREYS.black};
`;

const SBSPB_QN = styled(SBSPB)`
  background-color: ${BK_COLORS?.blue || BRAND.secondary};
  color: ${FONT.light || GREYS.black};
  border-radius: 99px;
  border-color: ${BK_COLORS?.blue};
`;

const SBSNPB_QN = styled(SBSNPB)`
  background-color: ${BK_COLORS?.blue || BRAND.secondary};
  color: ${FONT.light || GREYS.black};
  border-radius: 99px;
  border-color: ${BK_COLORS?.blue};
`;

const BSB_UI = styled(BSB)`
  z-index: 99;
`;

export {
  SBSD_QN as SBSD,
  SBSU,
  SBSL,
  SBSLH6_QN as SBSLH6,
  SBSLH6B_QN as SBSLH6B,
  SBSNPB_QN as SBSNPB,
  SBSPB_QN as SBSPB,
  SpinnerObj,
  BSB_UI as BSB,
};
