import {
  HD,
  RU,
  SD,
  RCD,
  RBB,
  CD,
  ED,
  DCD,
  DCN,
  SEAr,
  RDC,
} from '../../../core__ui-library/apps/RacingEventsApp/core__raceOutcomesSelection';
import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const RCD_EX = styled(RCD)`
  color: ${GREYS.black};
  & span:after {
    border-color: ${GREYS.black};
  }
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 14px;
  }
`;

const HD_EX = styled(HD)`
  margin-top: 16px;
  box-shadow: none;
  @media (max-width: ${BREAKPOINT_S}) {
    border-radius: 0;
  }
`;

export { HD_EX as HD, RU, SD, RCD_EX as RCD, RBB, CD, ED, DCD, DCN, SEAr, RDC };
