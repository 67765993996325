import {
  RL,
  CD,
  RCD,
  RC,
  RU,
  ED,
} from '../../../core__ui-library/apps/RacingEventsApp/core__raceListingPage';
import styled from 'styled-components';
import { GREYS, BK_COLORS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const RL_EX = styled(RL)`
  @media (max-width: ${BREAKPOINT_S}) {
    border-radius: 0;
  }
`;

const CD_EX = styled(CD)`
  color: ${GREYS.white};
  background-color: ${BK_COLORS.blue};
  border-bottom: 1px solid ${BK_COLORS.aliceBlue10};
  @media (max-width: ${BREAKPOINT_S}) {
    border-radius: 0;
  }
  span::after {
    border-color: ${GREYS.white};
  }
`;

const RCD_EX = styled(RCD)`
  border-bottom: 1px solid ${GREYS.doveD};
`;

const RC_EX = styled(RC)`
  font-weight: 700;
`;

export { RL_EX as RL, CD_EX as CD, RCD_EX as RCD, RC_EX as RC, RU, ED };
