import {
  BU,
  BD,
  BCLC,
  BCL,
  BCSM,
  BSMD,
  BMD,
  BetslipContentBetBoxPot,
  FBPR,
  BBh5,
  ENS,
  ERR,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__betBox';
import styled, { css } from 'styled-components';
import { GREYS, FONT, BK_COLORS, FUNCTIONAL } from 'UI/globals/colours';

const BMD_QN = styled(BMD)`
  margin: initial;
  border: 1px solid ${BK_COLORS.aliceBlue10};
  box-shadow: initial;
  border-radius: inherit;
`;

const BCL_QN = styled(BCL)`
  background-color: ${FONT.secondary};
  color: ${GREYS.black};
  font-size: 10px;
  font-weight: 800;
`;
const ENS_QN = styled(ENS)`
  color: ${GREYS.black};
`;

const BSMD_QN = styled(BSMD)`
  ${({ showBonusText }) =>
    showBonusText &&
    css`
      color: ${GREYS.black};
      background: ${FUNCTIONAL.positiveL};
    `}

  ${({ arrow }) =>
    arrow &&
    css`
      display: none;
    `}
`;

export {
  BU,
  BD,
  BCLC,
  BCL_QN as BCL,
  BCSM,
  BSMD_QN as BSMD,
  BMD_QN as BMD,
  BetslipContentBetBoxPot,
  FBPR,
  BBh5,
  ENS_QN as ENS,
  ERR,
};
