import styled, { css } from 'styled-components';
import { FONT, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_M, BREAKPOINT_XL } from 'UI/globals/breakpoints';

// Main div to display watch button
export const MI = styled.div`
  margin: 16px;
  padding: 16px;
  ${({ isStreamViewPortMounted }) =>
    !isStreamViewPortMounted &&
    css`
      background-color: ${BRAND.primaryD};
      :empty {
        background-color: unset;
      }
    `}
  ${({ isStreamViewPortMounted }) =>
    isStreamViewPortMounted &&
    css`
      text-align: -webkit-center;
    `}
  color: ${FONT.light};
  ${({ imageUrl, isStreamViewPortMounted }) =>
    imageUrl &&
    !isStreamViewPortMounted &&
    css`
      background-image: url(${imageUrl});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 100%;
    `}
`;

export const HD = styled.div`
  margin-bottom: 16px;
  color: ${FONT.secondary};
  font-size: 20px;
  font-weight: bold;
  display: flex;
  gap: 10px;
`;

export const SD = styled.div`
  font-size: 16px;
`;

export const VD = styled.video`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: auto;
  @media (max-width: ${BREAKPOINT_M}) and (orientation: landscape) {
    max-height: 60vh;
    width: auto;
  }
  @media (min-width: ${BREAKPOINT_XL}) and (orientation: landscape) {
    height: 60vh;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const IF = styled.iframe`
  width: 100%;
  position: relative;
  @media (max-width: ${BREAKPOINT_M}) and (orientation: portrait) {
    height: 75vw;
  }
  @media (max-width: ${BREAKPOINT_M}) and (orientation: landscape) {
    height: 60vh;
    width: auto;
  }
  @media (min-width: ${BREAKPOINT_XL}) and (orientation: landscape) {
    height: 60vh;
    width: 46vw;
  }
`;

export const RMG_CONTAINER = styled.div`
  position: relative;
  z-index: 1000;
  aspect-ratio: 25/18;
  width: 100%;
  @media (min-width: ${BREAKPOINT_XL}) and (orientation: landscape) {
    width: 800px;
  }
  iframe {
    height: 100% !important;
  }
`;
